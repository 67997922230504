import Vue from "vue";
import Vuex from "vuex";
import modules from './modules'

Vue.use(Vuex);

export default new Vuex.Store({
    modules,
    state: {
      user: {
        cpfCnpj: null,
        id: null,
      },
    },
    mutations: {
      setUser(state, user) {
        state.user = { ...state.user, ...user };
      },
      clearUser(state) {
        state.user = { cpfCnpj: null, id: null };
      },
    },
    actions: {
      loginUser({ commit }, userData) {
        // Faça a autenticação do usuário e obtenha os dados do usuário
        // Por exemplo, após autenticar o usuário com sucesso:
        const user = {
          cpfCnpj: userData.cpfCnpj,
          id: userData.id,
        };
  
        commit('setUser', user);
      },
      logoutUser({ commit }) {
        // Realize o logout do usuário
        // Por exemplo, após o logout do usuário:
        commit('clearUser');
      },
    },
  });