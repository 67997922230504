import guest from "@/middleware/guest";
import { routes as termos } from "./termos/";
import { routes as somos } from "./somos/";
import { routes as experimente } from "./experimente/";

export default [
  {
      path: "",
      component: () => import("@/modules/layout/AboutLayout"),
      children: [
          {
              path: "/sobre",
              name: "sobre",
              meta: { middleware: [guest] },
              component: () => import("./Sobre"),
          },
          ...termos,
          ...somos,
          ...experimente,
      ]
  },
];
