export default {

    sendSmsOrEmail: { 
        url: 'sendOffer', 
        method: 'post', 
    },
    sendEmailOrWhatsapp: {
        url: 'renner/sendAgreement',
        method: 'post',
    },
    getDebtsRenner: {
        url: 'renner/debts',
        method: 'post',
    },
    agreementBoletoRenner: {
        url: 'renner/agreement',
        method: 'post',
    },

}