<template>
    <!-- <component v-bind:is="layout"></component> -->
  <v-overlay :value="loading" v-if="loading">
    <v-progress-circular
      indeterminate
      size="64"
      color="accent"
    ></v-progress-circular>
  </v-overlay>
  <router-view v-else>

  </router-view>

</template>

<script>
import { mapState } from 'vuex';

export default {
  name: "App",
  computed: {
    ...mapState('auth', ['loading']),
  },
  mounted() {
    localStorage.setItem('authLogControl', false);
  },
  components: {
  }
};

</script>

