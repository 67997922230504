import { routes as home } from '@/pages/home';
import { routes as dashboard } from '@/pages/dashboard';
import { routes as sobre } from '@/pages/sobre';
import { routes as mailing } from '@/pages/landing';
import { routes as pesquisa } from '@/pages/pesquisa';
import { routes as erro } from '@/pages/erro';
import { routes as siscobra } from '@/pages/siscobra';

export default [
    ...home,
    ...dashboard,
    ...sobre,
    ...mailing,
    ...pesquisa,
    ...erro,
    ...siscobra,
    {
        path: "/:pathMatch(.*)",
        name: "notFound",
        component: () =>
          import("@/pages/NotFound"),
    },
];
