import guest from "@/middleware/guest";

export default [
  {
      path: "",
      component: () => import("@/modules/layout/SiscobraLayout"),
      children: [
          {
              path: "/auth/siscobra",
              name: "siscobra",
              meta: { middleware: [guest] },
              component: () => import("./Siscobra"),
          },
      ]
  },
];
