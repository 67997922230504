import Vue from 'vue';

const success = (message = '', title = '', timeout = 1000) => {
    Vue.prototype.flashMessage.show({
        title,
        message,
        timeout,
        status: 'success',
        blockClass: 'custom-block-class',
    });
};

const error = (message = '', title = '', timeout = 1000, html = null) => {
    Vue.prototype.flashMessage.show({
        title,
        message,
        timeout,
        status: 'error',
        blockClass: 'custom-block-class',
        html
    });
}


export default {
    success,
    error,
}
