import auth from "@/middleware/auth";
import { routes as cadastro } from "./cadastro/";
import { routes as meusBoletos } from "./meusBoletos/";


export default [
    {
        path: "/dashboard",
        component: () => import("@/modules/layout/DashboardLayout"),
        children: [
            {
                path: "/",
                name: "dashboard",
                meta: { middleware: [auth] },
                component: () => import("./Dashboard")
            },
            ...cadastro,
            ...meusBoletos,
        ],
    },
];
