import { store as auth } from '@/modules/auth';
import { store as layout } from '@/modules/layout';
import { store as cadastro } from '@/pages/dashboard/cadastro';
import { store as meusBoletos } from '@/pages/dashboard/meusBoletos';
import { store as meusContratos } from '@/pages/dashboard/meusContratos';

export default {
    auth,
    layout,
    cadastro,
    meusBoletos,
    meusContratos,

}
