import services from '@/http';
import { user } from '@/http';

export const ActionSendEmailOrSmsText = (_, payload) => {
    user.sendSmsOrEmail.data = payload;

    return services.http.request(user.sendSmsOrEmail)
        .then(response => {
            return response;
        })
        .catch((err) => {
            return err;
        });
}

export const ActionGetDebtsRenner = (_, payload) => {
    user.getDebtsRenner.data = payload;
    return services.http.request(user.getDebtsRenner)
        .then(response => {
            return response.data.results;
        }).catch((e) => {
          throw e
        })
}

export const ActionSetAgreementBoletoRenner = (_, payload) => {
    user.agreementBoletoRenner.data = payload;
    return services.http.request(user.agreementBoletoRenner)
        .then(response => {
            return response.data.results;
        }).catch((e) => {
          throw e
        })
}

export const ActionContatoWhatsapp = ( _, payload) => {
    user.contatoWhatsapp.data = payload;
    return services.http.request(user.contatoWhatsapp)
        .then(response => {
            return response.data.results;
        }).catch((e) => {
            throw e;
        })
}



export const ActionSendAgreementEmailOrWhatsappRenner = (_, payload) => {
    user.sendEmailOrWhatsapp.data = payload;
    return services.http.request(user.sendEmailOrWhatsapp)
        .then(response => {
            return response.data.results;
        }).catch((e) => {
          throw e
        })
}
