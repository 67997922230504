import Vue from "vue";
import App from "./App";
import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Vuelidate from 'vuelidate';
import FlashMessage from '@smartweb/vue-flash-message';
import Axios from '@/http'
import money from 'v-money';
import VModal from 'vue-js-modal'
import Inputmask from 'inputmask';
import { EventBus } from '@/utils/EventBus';


Vue.prototype.$dataLayer = window.dataLayer || [];

Vue.use(Inputmask);

// register directive v-money and component <money>
Vue.use(money,  {
  decimal: ',',
  thousands: '.',
  prefix: 'R$ ',
  precision: 2,
  masked: false
});


Vue.use(Axios);

Vue.use(FlashMessage);

Vue.use(VModal);

Vue.config.productionTip = false;





const customData = (object, friendlyPageName) => {
  console.log('OBJECT: ' + JSON.stringify(object))
  return { 
    page: {
      name: friendlyPageName,
    },
    site: {
      versao: process.env.VUE_APP_VERSION,
      channel: 'portal-de-negociacao',
    },
    user: {
      id: object.cpfCnpj,
      logged: false, // Defina como true ou false, dependendo do status de login do usuário.
    },
    page_location: object.page_location
  }
};



new Vue({
  router,
  store,
  vuetify,
  
  Vuelidate,
  render: (h) => h(App),
  created() {
    // Escute o evento personalizado globalmente
    this.$root.$on('updatePageView', (pageName) => {
      const userDataStorage = JSON.parse(localStorage.getItem("userData"));
      const credor = JSON.parse(localStorage.getItem("credor"));

      console.log('customData updatePageView: ' + JSON.stringify(pageName + ' ' + userDataStorage));
      
      const protocol = window.location.protocol;
      const host = window.location.host;
      const path = window.location.pathname;
      const pageLocationURL = protocol + '//' + host + path;
      let pageLocation = userDataStorage ? userDataStorage.page_location : pageLocationURL;
      if(credor == 2){
        pageLocation = protocol + '//' + host + "/jornada-amigavel" + path;
      }
      // Dispare manualmente o evento de page view
      // const to = { name: pageName, path: `/${pageName}` };  // Pode ajustar o caminho conforme necessário
      console.log('DISPARO PAGE VIEW MAIN JS updatePageView 222222');
      this.$dataLayer.push({ event: 'page-view', page_location: pageLocation });
    });

    EventBus.$on('updatePageView', (friendlyPageName) => {
      // console.log('friendlyPageName ' + JSON.stringify(friendlyPageName));
      const userDataStorage = JSON.parse(localStorage.getItem("userData"));
      const credor = JSON.parse(localStorage.getItem("credor"));

      const protocol = window.location.protocol;
      const host = window.location.host;
      const path = window.location.pathname;
      const pageLocationURL = protocol + '//' + host + path;
      let pageLocation = userDataStorage ? userDataStorage.page_location : pageLocationURL;
      if(credor == 2){
        pageLocation = protocol + '//' + host + "/jornada-amigavel/" + friendlyPageName;
      }
      // console.log('EventBus updatePageView: ' + JSON.stringify(userDataStorage));
      // Dispare manualmente o evento de page view
      // const to = { name: pageName, path: `${pageName}` };  // Pode ajustar o caminho conforme necessário
      console.log('DISPARO PAGE VIEW --------------- EventBus updatePageView 3333333:  ' + JSON.stringify(customData(userDataStorage, friendlyPageName)));
      this.$dataLayer.push({ event: 'page-view', page_location: pageLocation });
    });


  },
}).$mount("#app");
