import guest from "@/middleware/guest";

export default [
  {
      path: "",
      component: () => import("@/modules/layout/HomeLayout"),
      children: [
          {
              path: "/",
              name: "home",
              meta: { middleware: [guest] },
              component: () => import("./Home"),
          }
      ]
  },
];
